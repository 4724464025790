import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 重复重定向控制台报错问题
const originalPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    name: 'courseInfo',
    path: '/courseInfo/:courseId',
    component: () => import('@/views/courseInfo/index.vue')
  },
  {
    name: 'overCourseInfo',
    path: '/overCourseInfo/:courseId',
    component: () => import('@/views/overCourseInfo/index.vue')
  },
  {
    name: 'overChildrenCourseInfo',
    path: '/overChildrenCourseInfo/:courseId',
    component: () => import('@/views/overChildrenCourseInfo/index.vue')
  },
  {
    name: 'myHomework',
    path: '/myHomework',
    component: () => import('@/views/myHomework/index.vue')
  },
  {
    name: 'myExamList',
    path: '/myExamList',
    component: () => import('@/views/myExamList/index.vue')
  },
  {
    name: 'accountSafe',
    path: '/accountSafe',
    component: () => import('@/views/accountSafe/index.vue')
  },
  {
    name: 'updatePassword',
    path: '/updatePassword',
    component: () => import('@/views/updatePassword/index.vue')
  },
  {
    name: 'userCenterPage',
    path: '/userCenterPage',
    component: () => import('@/views/userCenterPage/index.vue')
  },
  {
    name: 'diaochawenjuan',
    path: '/diaochawenjuan',
    component: () => import('@/views/diaochawenjuan/index.vue')
  },
  {
    name: 'writeQuestionnaire',
    path: '/writeQuestionnaire',
    component: () => import('@/views/writeQuestionnaire/index.vue')
  },
  {
    name: 'live', // 直播
    path: '/live/:id',
    component: () => import('@/views/live/index.vue')
  },
  {
    name: 'offLine', // 线下课程
    path: '/offLine/:id',
    component: () => import('@/views/offLine/index.vue')
  },
  {
    name: 'playback', // 回放
    path: '/playback',
    component: () => import('@/views/playBack/index.vue')
  },
  {
    name: 'trainingClass', // 培训班次
    path: '/trainingClass',
    component: () => import('@/views/trainingClass/index.vue')
  }, {
    name: 'trainingClass2', // 培训班次
    path: '/trainingClass2',
    component: () => import('@/views/trainingClass2/index.vue')
  },
  {
    name: 'studyCenter', // 学习中心
    path: '/studyCenter',
    component: () => import('@/views/studyCenter/index.vue')
  },
  {
    name: 'examCenter', // 考试中心
    path: '/examCenter',
    component: () => import('@/views/examCenter/index.vue')
  },
  {
    name: 'examItemInfo', // 考试详情
    // path: '/live/:liveClassRoomId',
    path: '/examItemInfo',
    component: () => import('@/views/examItemInfo/index.vue')
  },
  {
    name: 'newcomerPost', // 新人驿站
    path: '/newcomerPost',
    component: () => import('@/views/newcomerPost/index.vue')
  },
  {
    name: 'liveRoom', // 直播大厅
    // path: '/live/:liveClassRoomId',
    path: '/liveRoom',
    component: () => import('@/views/liveRoom/index.vue')
  },
  {
    name: 'boutiqueCourse', // 精品课程
    // path: '/live/:liveClassRoomId',
    path: '/boutiqueCourse',
    component: () => import('@/views/boutiqueCourse/index.vue')
  },
  {
    name: 'childrenChaptersCourse',
    path: '/childrenChaptersCourse/:courseId',
    component: () => import('@/views/childrenChaptersCourse/index.vue')
  },
  {
    name: 'trainDynamic', // 培训动态
    // path: '/live/:liveClassRoomId',
    path: '/trainDynamic',
    component: () => import('@/views/trainDynamic/index.vue')
  },
  {
    name: 'trainDynamicInfo', // 培训动态详情
    path: '/trainDynamicInfo/:dynamicId',
    component: () => import('@/views/trainDynamicInfo/index.vue')
  },
  {
    name: 'helpCenter', // 帮助中心
    path: '/helpCenter',
    component: () => import('@/views/helpCenter/index.vue')
  },
  {
    name: 'userCenterPage_QR',
    path: '/userCenterPage_QR',
    component: () => import('@/views/userCenterPage_QR/index.vue')
  },
  {
    name: 'studyHistory',
    path: '/studyHistory',
    component: () => import('@/views/studyHistory/index.vue')
  },
]
const router = new VueRouter({
  routes
})

export default router
